















































import { ContractType, CrewMemberModel, CrewMemberVesselEnquiryModel } from "@/api/generated";
import { Vue, Component, PropSync, Prop, Watch } from "vue-property-decorator";
import Api from "@/api";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselEnquiryCrewMemberAssignmentDialog extends Vue {
  @Prop({ default: false }) private isCompaniesTable!: boolean;
  @PropSync("dialog") private syncedDialog!: boolean;
  @Prop(String) private crewMemberVesselEnquiryId!: string;
  @Prop(String) private vesselEnquiryId!: string;
  private crewMemberVesselEnquiry: CrewMemberVesselEnquiryModel = {};
  private selectedCrewMember: Array<CrewMemberModel> = [];
  private crewMembers: Array<CrewMemberModel> = [];
  private loading = false;
  private tableLoading = false;
  private headers: Array<DataTableHeader<CrewMemberModel>> = [
    { text: "Name", value: nameof<CrewMemberModel>("fullname") },
    { text: "Date of Birth", value: nameof<CrewMemberModel>("dateOfBirth") },
    {
      text: "Passport Expiry",
      value: nameof<CrewMemberModel>("passportExpiry")
    },
    { text: "Rating", value: nameof<CrewMemberModel>("rating") }
  ];

  get getSelectedCrewMember() {
    return this.selectedCrewMember[0];
  }

  @Watch("syncedDialog", { immediate: true })
  private async onSyncedDialogChanged() {
    if (!this.syncedDialog) {
      this.crewMembers = [];
    }

    await this.getCrewMemberVesselEnquiry();
    await this.getCrewMembers();
  }

  private async getCrewMemberVesselEnquiry() {
    try {
      const response = await Api.CrewMemberVesselEnquiryService.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdGet(
        this.crewMemberVesselEnquiryId
      );

      this.crewMemberVesselEnquiry = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch current assignment");
    }
  }

  private async getCrewMembers() {
    try {
      this.tableLoading = true;
      const response = await Api.CrewMemberVesselEnquiryService.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdRecommendationFilterGet(
        this.crewMemberVesselEnquiryId,
        this.isCompaniesTable ? ContractType.NUMBER_1 : ContractType.NUMBER_0
      );

      this.crewMembers = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to get available crew members");
    } finally {
      this.tableLoading = false;
    }
  }

  private async onAssign() {
    if (!this.getSelectedCrewMember) {
      return;
    }

    try {
      this.loading = true;
      this.crewMemberVesselEnquiry.crewMemberId = this.getSelectedCrewMember.crewMemberId;

      await Api.CrewMemberVesselEnquiryService.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdPut(
        this.crewMemberVesselEnquiryId,
        this.crewMemberVesselEnquiry
      );

      snackbarModule.setSnackbarMessage(
        "Crew member successfully assigned to job role"
      );

      this.$emit("crew-member-assigned");
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to assign crew member to job role slot"
      );
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
