



































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import JobRoleAutocomplete from "@/components/common/job-role/JobRoleAutocomplete.vue";
import { JobRoleDescription } from "@/components/vessel/enquiry/VesselEnquiryJobRoleTable.vue";
import { JobRoleModel, VesselEnquiryModel } from "@/api/generated";
import Api from "@/api";
import Discriminator from '../../types/Discriminator';

@Component({ components: { JobRoleAutocomplete } })
export default class VesselEnquiryJobRoleAddDialog extends Validation {
  @Ref() private readonly form!: VForm;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(String) private vesselEnquiryId!: string;
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;

  private enquiry: VesselEnquiryModel = {};
  private selectedJobRole: JobRoleModel | null = null;
  private loading = false;
  private model: JobRoleDescription = {
    jobRoleId: "",
    jobRole: "",
    quantity: 1,
    crewMemberId: "",
    startDate: "",
    endDate: "",
    vesselEnquiryId: ""
  };

  @Watch("selectedJobRole")
  private async onSelectedJobRoleChanged() {
    if (!this.selectedJobRole) {
      return;
    }

    const enquiryResponse = await Api.VesselEnquiryService.apiVesselenquiryVesselEnquiryIdGet(
      this.vesselEnquiryId
    );

    this.enquiry = enquiryResponse.data;

    this.model = {
      jobRoleId: this.selectedJobRole.jobRoleId,
      jobRole: this.selectedJobRole.name,
      crewMemberId: undefined,
      vesselEnquiryId: this.enquiry.vesselEnquiryId,
      quantity: 1,
      startDate: this.enquiry.startDate,
      endDate: this.enquiry.endDate
    };
  }

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onAddJobRole() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      for (let i = 0; i < this.model.quantity ?? 0; i++) {
        await Api.CrewMemberVesselEnquiryService.apiCrewmembervesselenquiryPost(
          this.model
        );
      }
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }

    this.$emit("job-role-created", this.model);
  }
}
