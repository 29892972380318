




































































































import { CrewMemberVesselEnquiryModel } from "@/api/generated";
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import { Vue, Component, Prop } from "vue-property-decorator";
import nameof from "@/utility/nameof";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);

@Component
export default class VesselEnquiryCrewMemberSelectionTable extends Vue {
  @Prop(Array) private crewMemberVesselEnquiries!: Array<
    CrewMemberVesselEnquiryModel
  >;
  @Prop(String) jobRoleId!: string;
  @Prop({ type: Boolean, default: false }) private readonly!: string;

  private headers: Array<DataTableHeader<CrewMemberVesselEnquiryModel>> = [
    { text: "", value: "actions", sortable: false },
    {
      text: "Crew Member",
      value: nameof<CrewMemberVesselEnquiryModel>("crewMemberFullname"),
      sortable: false
    },
    {
      text: "Available",
      value: nameof<CrewMemberVesselEnquiryModel>("available"),
      sortable: false
    },
    {
      text: "Certified",
      value: nameof<CrewMemberVesselEnquiryModel>("certified"),
      sortable: false
    },
    {
      text: "Passport",
      value: nameof<CrewMemberVesselEnquiryModel>("validPassport"),
      sortable: false
    },
    {
      text: "Job Role",
      value: nameof<CrewMemberVesselEnquiryModel>("validJobRole"),
      sortable: false
    },
    {
      text: "Rating",
      value: nameof<CrewMemberVesselEnquiryModel>("crewMemberRating"),
      sortable: false
    }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  get items() {
    const items = this.crewMemberVesselEnquiries.filter(
      x => x.jobRoleId === this.jobRoleId
    );
    return items;
  }
}
