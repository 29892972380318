
















import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { CertificateTypeModel } from "@/api/generated";
import Discriminator from '@/types/Discriminator';
import getContractType from '../../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CertificateTypeAutocomplete extends Vue {
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;

  private loading = false;
  private certificateTypes: Array<CertificateTypeModel> = [];

  private async created() {
    try {
      this.loading = true;
      const response = await Api.CertificateTypeService.apiCertificatetypeDiscriminatorGet(getContractType(this.discriminator));
      this.certificateTypes = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to load certificate types");
    } finally {
      this.loading = false;
    }
  }
}
