































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import CountryAutocomplete from "@/components/common/country/CountryAutocomplete.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { VesselEnquiryUpdateModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { CountryAutocomplete } })
export default class CloseVesselEnquiryDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(String) private vesselEnquiryId!: string;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: VesselEnquiryUpdateModel = {};

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    const response = await Api.VesselEnquiryService.apiVesselenquiryVesselEnquiryIdGet(
      this.vesselEnquiryId
    );
    this.model = response.data;
    this.form?.reset();
  }

  private async onVesselEnquiryClose() {
    if (!this.form.validate()) {
      return;
    }

    this.model.closed = true;

    try {
      this.loading = true;
      await Api.VesselEnquiryService.apiVesselenquiryVesselEnquiryIdPut(
        this.vesselEnquiryId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Vessel enquiry closed");
      this.$emit("enquiry-closed");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to close vessel enquiry");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
