






















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Api from "@/api";
import { getModule } from "vuex-module-decorators";
import VesselEnquiryCrewMemberSelectionTable from "@/components/vessel/enquiry/VesselEnquiryCrewMemberSelectionTable.vue";
import VesselEnquiryJobRoleCreateDialog from "@/components/dialogs/VesselEnquiryJobRoleCreateDialog.vue";
import VesselEnquiryCrewMemberAssignmentDialog from "@/components/dialogs/VesselEnquiryCrewMemberAssignmentDialog.vue";
import {
  CrewMemberVesselEnquiryModel,
  VesselEnquiryModel
} from "@/api/generated";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import UserModule from "@/store/userModule";
import MenuWrapper from "@/components/common/menu-items/MenuWrapper.vue";
import LandWorkerAllocationMenuItems from "@/components/common/menu-items/Groups/LandWorkerAllocationMenuItems.vue";
import VesselAllocationMenuItems from "@/components/common/menu-items/Groups/VesselAllocationMenuItems.vue";
import Discriminator from '@/types/Discriminator';

const userModule = getModule(UserModule);

@Component({
  components: {
    VesselEnquiryCrewMemberSelectionTable,
    VesselEnquiryJobRoleCreateDialog,
    VesselEnquiryCrewMemberAssignmentDialog,
    MenuWrapper,
    LandWorkerAllocationMenuItems,
    VesselAllocationMenuItems
  }
})
export default class VesselEnquiryAllocationCard extends Vue {
  @Prop(String) private vesselEnquiryId!: string;
  @Prop({ type: Boolean, default: false }) private readonly!: string;
  @Prop({ default: false }) public isCompaniesTable!: boolean;
  public vesselEnquiry: VesselEnquiryModel = {};
  public crewMemberVesselEnquiries: Array<CrewMemberVesselEnquiryModel> = [];
  private selectedCrewMemberVesselEnquiry: CrewMemberVesselEnquiryModel | null = null;
  private createDialog = false;
  private assignmentDialog = false;
  public documentLoading = false;
  public panels = [0]

  @Watch("vesselEnquiryId", { immediate: true })
  private async onVesselEnquiryIdChanged() {
    const response = await Api.CrewMemberVesselEnquiryService.apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet(
      this.vesselEnquiryId
    );
    const enquiryResponse = await Api.VesselEnquiryService.apiVesselenquiryVesselEnquiryIdGet(
      this.vesselEnquiryId
    );

    this.vesselEnquiry = enquiryResponse.data;
    this.crewMemberVesselEnquiries = response.data;
  }

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  public get firstCrewMemberVesselEnquiry() : CrewMemberVesselEnquiryModel | undefined {
    return this.crewMemberVesselEnquiries[0];
  }

  private get jobRoleItems() {
    const jobRoles: Array<CrewMemberVesselEnquiryModel> = [];

    if (!this.crewMemberVesselEnquiries?.length) {
      return jobRoles;
    }

    const map: Map<string, CrewMemberVesselEnquiryModel> = new Map<
      string,
      CrewMemberVesselEnquiryModel
    >();

    for (const item of this.crewMemberVesselEnquiries) {
      if (!item.jobRoleId) {
        continue;
      }

      map.set(item.jobRoleId, item);
    }

    map.forEach(x => jobRoles.push(x));

    return jobRoles;
  }

  private async onRemoveJobRole(role: CrewMemberVesselEnquiryModel) {
    if (!role.crewMemberVesselEnquiryId) {
      return;
    }

    await Api.CrewMemberVesselEnquiryService.apiCrewmembervesselenquiryCrewMemberVesselEnquiryIdDelete(
      role.crewMemberVesselEnquiryId
    );

    const response = await Api.CrewMemberVesselEnquiryService.apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet(
      this.vesselEnquiryId
    );

    this.crewMemberVesselEnquiries = response.data;
  }

  private onAssignAllocation(item: CrewMemberVesselEnquiryModel) {
    this.selectedCrewMemberVesselEnquiry = item;
    this.assignmentDialog = true;
  }

  private async onAssign() {
    const response = await Api.CrewMemberVesselEnquiryService.apiCrewmembervesselenquiryEnquiryVesselEnquiryIdGet(
      this.vesselEnquiryId
    );

    this.crewMemberVesselEnquiries = response.data;
  }
}
