








import Vue from "vue";
import MenuButton from "../MenuButton.vue";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import { CrewMemberVesselEnquiryModel, VesselEnquiryModel } from '../../../../api/generated/api';

export default Vue.extend({
  props: ['vesselEnquiryId'],
  components: {
    MenuButton
  },
  methods: {
    handleLoadingChanged(isLoading: boolean) {
      this.$emit('loading', isLoading);
    },

    async onGenerateLetterToBhcSriLanka() {
      this.handleLoadingChanged(true);

      try {
        const response = await Api.ReportService.apiReportLetterbhcsrilankaIdGet(
          this.vesselEnquiryId
        );

        FileDownloadUtility.DownloadFile(
          response.data,
          `Letter To BHC Sri Lanka.docx`
        );
      } finally {
        this.handleLoadingChanged(false);
      }
    },

    async onGenerateLetterToBhcIndia() {
      this.handleLoadingChanged(true);
      
      try {
        const response = await Api.ReportService.apiReportLetterbhcindiaIdGet(
          this.vesselEnquiryId
        );
        
        FileDownloadUtility.DownloadFile(response.data, `Letter To BHC India.docx`);
      } finally {
        this.handleLoadingChanged(false);
      }
    },

    async onGenerateLetterToBhcGhana() {
      this.handleLoadingChanged(true);
  
      try {
        const response = await Api.ReportService.apiReportLetterbhcaccraIdGet(
          this.vesselEnquiryId
        );
  
        FileDownloadUtility.DownloadFile(response.data, `Letter To BHC Accra.docx`);
      } finally {
        this.handleLoadingChanged(false);
      }
    }
  }
});
