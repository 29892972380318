
















import Vue from "vue";
import MenuButton from "../MenuButton.vue";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import { CrewMemberVesselEnquiryModel } from '@/api/generated';
import { AxiosResponse } from 'axios';

interface DownloadMethod {
  (crewMemberVesselEnquiryId: string): Promise<AxiosResponse>;
}

export default Vue.extend({
  props: ['item'],
  components: {
    MenuButton
  },
  methods: {
    async downloadEn() {
      this.download(
        (id) => Api.ReportService.apiReportFwaenglishIdGet(id),
        "English"
      )
    },

    async downloadEnSkilled() {
      this.download(
        (id) => Api.ReportService.apiReportFwaskilledenglishIdGet(id),
        "Skilled-English"
      )
    },

    async downloadHindi() {
      this.download(
        (id) => Api.ReportService.apiReportFwahindiIdGet(id),
        "Hindi"
      )
    },

    async downloadHindiSkilled() {
      this.download(
        (id) => Api.ReportService.apiReportFwaskilledhindiIdGet(id),
        "Skilled-Hindi"
      )
    },


    async downloadIndonesian() {
      this.download(
        (id) => Api.ReportService.apiReportFwaindonesiaIdGet(id),
        "Indonesia"
      )
    },

    async downloadIndonesianSkilled() {
      this.download(
        (id) => Api.ReportService.apiReportFwaskilledindonesianIdGet(id),
        "Skilled-Indonesia"
      )
    },

    async downloadSinhala() {
      this.download(
        (id) => Api.ReportService.apiReportFwasinhalaIdGet(id),
        "Sinhala"
      )
    },

    async downloadSinhalaSkilled() {
      this.download(
        (id) => Api.ReportService.apiReportFwaskilledsinhalaIdGet(id),
        "Skilled-Sinhala"
      )
    },

    async downloadTagalog() {
      this.download(
        (id) => Api.ReportService.apiReportFwatagalogIdGet(id),
        "Tagalog"
      )
    },

    async downloadAkan() {
      this.download(
        (id) => Api.ReportService.apiReportFwaakanIdGet(id),
        "Akan"
      )
    },

    async downloadAkanSkilled() {
      this.download(
        (id) => Api.ReportService.apiReportFwaskilledakanIdGet(id),
        "Skilled-Akan"
      )
    },

    async download(getFile: DownloadMethod, language: string) {
      try {
        const item = this.item as CrewMemberVesselEnquiryModel;

        this.$emit('loading', true);

        if (
          !item.crewMemberVesselEnquiryId ||
          !item.crewMemberId
        ) {
          return;
        }

        const response = await getFile(item.crewMemberVesselEnquiryId);

        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.crewMemberFullname}-FWA${language}.docx`
        );
      } finally {
        this.$emit('loading', false);
      }
    }
  }
});
