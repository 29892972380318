












import Vue from "vue";
import BHCLetterMenuItems from '@/components/common/menu-items/Groups/BHCLetterMenuItems.vue';
import LandWorkerAgreement from "../WorkerAgreements/LandWorkerAgreement.vue";
import LandWorkerAgreementAkan from "../WorkerAgreements/LandWorkerAgreementAkan.vue";
import FlightIndemnityMenuItem from "../enquiry/FlightIndemnityMenuItem.vue";
import GDPRMenuItems from '@/components/common/menu-items/Groups/GDPRMenuItems.vue';
import InvitationLetterMenuItem from "../enquiry/InvitationLetterMenuItem.vue";
import NextOfKinMenuItem from "../enquiry/NextOfKinMenuItem.vue";

export default Vue.extend({
  props: ['vesselEnquiryId', 'crewMemberVesselEnquiry'],
  components: {
    BHCLetterMenuItems,
    LandWorkerAgreement,
    FlightIndemnityMenuItem,
    GDPRMenuItems,
    LandWorkerAgreementAkan,
    InvitationLetterMenuItem,
    NextOfKinMenuItem
  },
  methods: {
    handleLoadingChanged(isLoading: boolean) {
      this.$emit('loading', isLoading);
    }
  },
  computed: {
    crewMemberVesselEnquiryId() {
      return this.crewMemberVesselEnquiry.crewMemberVesselEnquiryId
    }
  }
});
