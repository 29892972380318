























import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { ContractType, VesselModel } from "@/api/generated";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselAutocomplete extends Vue {
  @Prop({default: ContractType.NUMBER_0}) discriminator?: ContractType;

  private loading = false;
  private vessels: Array<VesselModel> = [];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    try {
      this.loading = true;

      let response;

      if (this.agencyAdminRole) {
        response = await Api.VesselService.apiVesselByagencyWithSponsoredUserIdGet(
          userModule.userId
        );
      } else {
        response = await Api.VesselService.apiVesselWithSponsoredGet();
      }

      const items: VesselModel[] = response.data;

      this.vessels = items.filter(x => x.discriminator === this.discriminator);
    } catch {
      snackbarModule.setSnackbarMessage("Failed to load vessels");
    } finally {
      this.loading = false;
    }
  }
}
