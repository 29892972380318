
























import { Component, PropSync, Prop, Ref } from "vue-property-decorator";
import Validation from "@/mixins/validation";
import CertificateTypeAutocomplete from "@/components/common/certificate-type/CertificateTypeAutocomplete.vue";
import { CertificateTypeVesselEnquiryCreateModel } from "@/api/generated";
import { VForm } from "@/types/vForm";
import Api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Discriminator from '@/types/Discriminator';

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { CertificateTypeAutocomplete } })
export default class VesselEnquiryCertificateTypeCreateDialog extends Validation {
  @Ref() private form!: VForm;
  @Prop({default: Discriminator.VESSELS}) discriminator!: Discriminator;
  @PropSync("dialog") private syncedDialog!: boolean;
  @Prop(String) private vesselEnquiryId!: string;

  private loading = false;
  private model: CertificateTypeVesselEnquiryCreateModel = {
    vesselEnquiryId: "",
    certificateTypeId: ""
  };

  private async onCertificateCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      this.model.vesselEnquiryId = this.vesselEnquiryId;
      await Api.CertificateTypeVesselEnquiryService.apiCertificatetypevesselenquiryPost(
        this.model
      );
      snackbarModule.setSnackbarMessage("Certificate requirement created");
      this.$emit("certificate-created");
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to create certificate requirement"
      );
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
