var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vessel-enquiry-crew-member-selection-table"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"no-data-text":"No certificates required","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.crewMemberFullname",fn:function(ref){
var item = ref.item;
return [(item.crewMemberId !== null)?_c('div',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'Crew Member Edit',
            params: { crewMemberId: item.crewMemberId }
          }}},[_vm._v(_vm._s(item.crewMemberFullname))])],1):_c('div',[_vm._v(" Unassigned ")])]}},{key:"item.available",fn:function(ref){
          var item = ref.item;
return [(item.crewMemberId !== null)?_c('v-icon',{attrs:{"color":item.available ? 'success' : 'error'}},[_vm._v(_vm._s(_vm._f("passFailNullable")(item.available)))]):_c('div',[_vm._v(" - ")])]}},{key:"item.certified",fn:function(ref){
          var item = ref.item;
return [(item.crewMemberId !== null)?_c('v-icon',{attrs:{"color":item.certified ? 'success' : 'error'}},[_vm._v(_vm._s(_vm._f("passFailNullable")(item.certified)))]):_c('div',[_vm._v(" - ")])]}},{key:"item.validPassport",fn:function(ref){
          var item = ref.item;
return [(item.crewMemberId !== null)?_c('v-icon',{attrs:{"color":item.validPassport ? 'success' : 'error'}},[_vm._v(_vm._s(_vm._f("passFailNullable")(item.validPassport)))]):_c('div',[_vm._v(" - ")])]}},{key:"item.crewMemberRating",fn:function(ref){
          var item = ref.item;
return [(item.crewMemberId !== null)?_c('v-rating',{attrs:{"readonly":""},model:{value:(item.crewMemberRating),callback:function ($$v) {_vm.$set(item, "crewMemberRating", $$v)},expression:"item.crewMemberRating"}}):_c('div',[_vm._v(" - ")])]}},{key:"item.validJobRole",fn:function(ref){
          var item = ref.item;
return [(item.crewMemberId !== null)?_c('v-icon',{attrs:{"color":item.validJobRole ? 'success' : 'error'}},[_vm._v(_vm._s(_vm._f("passFailNullable")(item.validJobRole)))]):_c('div',[_vm._v(" - ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(!_vm.readonly && !_vm.agencyAdminRole)?_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(item.crewMemberId)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('reassign-allocation', item)}}},[_c('v-list-item-title',[_vm._v("Reassign")])],1):_c('v-list-item',{on:{"click":function($event){return _vm.$emit('assign-allocation', item)}}},[_c('v-list-item-title',[_vm._v("Assign")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete-allocation', item)}}},[_c('v-list-item-title',[_vm._v("Remove")])],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }