






































import { CertificateTypeVesselEnquiryModel } from "@/api/generated";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Api from "@/api";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);

@Component
export default class VesselEnquiryRequiredCertificateList extends Vue {
  @Prop(String) private vesselEnquiryId!: string;
  @Prop({ type: Boolean, default: false }) private readonly!: string;
  private requiredCertificates: Array<CertificateTypeVesselEnquiryModel> = [];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  @Watch("vesselEnquiryId", { immediate: true })
  private async onVesselEnquiryIdChanged() {
    const response = await Api.CertificateTypeVesselEnquiryService.apiCertificatetypevesselenquiryEnquiryVesselEnquiryIdGet(
      this.vesselEnquiryId
    );

    this.requiredCertificates = response.data;
  }
}
