




import Vue from "vue";
import MenuButton from "../MenuButton.vue";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import { CrewMemberVesselEnquiryModel } from '@/api/generated';

export default Vue.extend({
  props: ['item'],
  components: {
    MenuButton
  },
  methods: {
    async onDownload() {
      try {
        const item = this.item as CrewMemberVesselEnquiryModel;

        this.$emit('loading', true);

        if (
          !item.crewMemberVesselEnquiryId ||
          !item.crewMemberId
        ) {
          return;
        }

        const response = await Api.ReportService.apiReportInvitationIdGet(
          item.crewMemberVesselEnquiryId
        );

        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.crewMemberFullname}-Invitation.docx`
        );
      } finally {
        this.$emit('loading', false);
      }
    }
  }
});
