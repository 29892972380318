











import Vue from "vue";
import MenuButton from "../MenuButton.vue";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import { CrewMemberVesselEnquiryModel } from '@/api/generated';
import { AxiosResponse } from 'axios';

interface DownloadMethod {
  (crewMemberVesselEnquiryId: string): Promise<AxiosResponse>;
}

export default Vue.extend({
  props: ['item'],
  components: {
    MenuButton
  },
  methods: {
    async downloadEn() {
      this.download(
        (id) => Api.ReportService.apiReportEnquiryCrewmansresponsibilityIdGet(id),
        "English"
      )
    },

    async downloadHindi() {
      this.download(
        (id) => Api.ReportService.apiReportEnquiryCrewmansresponsibilityhindiIdGet(id),
        "Hindi"
      )
    },

    async downloadIndonesia() {
      this.download(
        (id) => Api.ReportService.apiReportEnquiryCrewmansresponsibilityindonesianIdGet(id),
        "Indonesia"
      )
    },

    async downloadSinhala() {
      this.download(
        (id) => Api.ReportService.apiReportEnquiryCrewmansresponsibilitysinhalaIdGet(id),
        "Sinhala"
      )
    },

    async downloadTagalog() {
      this.download(
        (id) => Api.ReportService.apiReportEnquiryCrewmansresponsibilitytagalogIdGet(id),
        "Tagalog"
      )
    },

    async downloadAkan() {
      this.download(
        (id) => Api.ReportService.apiReportEnquiryCrewmansresponsibilityakanIdGet(id),
        "Akan"
      )
    },

    async download(getFile: DownloadMethod, language: string) {
      try {
        const item = this.item as CrewMemberVesselEnquiryModel;

        this.$emit('loading', true);

        if (
          !item.crewMemberVesselEnquiryId ||
          !item.crewMemberId
        ) {
          return;
        }

        const response = await getFile(item.crewMemberVesselEnquiryId);

        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.crewMemberFullname}-Crewmans Responsibility MP-${language}.docx`
        );
      } finally {
        this.$emit('loading', false);
      }
    }
  }
});
