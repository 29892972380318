
















import Vue from "vue";
import BHCLetterMenuItems from '@/components/common/menu-items/Groups/BHCLetterMenuItems.vue';
import FlightIndemnityMenuItem from "../enquiry/FlightIndemnityMenuItem.vue";
import GDPRMenuItems from '@/components/common/menu-items/Groups/GDPRMenuItems.vue';
import InvitationLetterMenuItem from "../enquiry/InvitationLetterMenuItem.vue";
import AgentsListMenuItem from "../enquiry/AgentsListMenuItem.vue";
import LetterOfUndertakingMenuItem from "../enquiry/LetterOfUndertakingMenuItem.vue";
import OkToBoardMenuItem from "../enquiry/OkToBoardMenuItem.vue";
import FWAMenuItems from "./FWAMenuItems.vue";
import SEAEnglishMenuItem from "../enquiry/SEAEnglishMenuItem.vue";
import NextOfKinMenuItem from "../enquiry/NextOfKinMenuItem.vue";
import CrewmansResponsibilityMenuItems from "./CrewmansResponsibilityMenuItems.vue";

export default Vue.extend({
  props: ['vesselEnquiryId', 'crewMemberVesselEnquiry'],
  components: {
    BHCLetterMenuItems,
    FlightIndemnityMenuItem,
    GDPRMenuItems,
    InvitationLetterMenuItem,
    AgentsListMenuItem,
    LetterOfUndertakingMenuItem,
    OkToBoardMenuItem,
    FWAMenuItems,
    SEAEnglishMenuItem,
    NextOfKinMenuItem,
    CrewmansResponsibilityMenuItems
  },
  methods: {
    handleLoadingChanged(isLoading: boolean) {
      this.$emit('loading', isLoading);
    }
  },
  computed: {
    crewMemberVesselEnquiryId() {
      return this.crewMemberVesselEnquiry.crewMemberVesselEnquiryId
    }
  }
});
